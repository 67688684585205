<template>
  <div>
    <form
      novalidate
      class="forgot-password-form md-layout"
      @submit.prevent="sendResetPasswordLink"
    >
      <md-card class="external-container">
        <div class="container">
          <md-card-header class="title-container">
            <div v-if="!verified" class="md-title title-color">Verify Email?</div>
            <div v-else class="md-title title-color">Forgot Password?</div>
          </md-card-header>

          <md-card-content class="email-container">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('email')">
                <label for="email">Email</label>
                <md-input
                  type="email"
                  name="email"
                  id="email"
                  autocomplete="email"
                  v-model="model.email"
                  @input="inputEmail"
                  :disabled="isSubmitting"
                />
                <span class="md-error" v-if="emailValidationError">{{
                  emailValidationError
                }}</span>
              </md-field>
            </div>
          </md-card-content>

          <div v-if="!verified" :class=" !model.email ? 'verify_email_msg' : 'verify_email_message'">
            Your account needs to verify
          </div>

          <div v-if="addRecaptcha">
            <md-card-actions style="justify-content:center;">
              <vue-recaptcha :sitekey="conf && conf.captcha && conf.captcha.siteKey || '6LcqJ0MhAAAAAOqsIND4poeS9JiVHZMKBteccf8V'" 
                ref="recaptcha" 
                :loadRecaptchaScript="true" 
                @verify="onCaptchaVerified"
                @expired="onExpired"
                >
              </vue-recaptcha>
            </md-card-actions>
            <div style="padding-bottom:15px; text-align:center;"
                class="md-error"
                v-if="recaptchaError"
                >{{ recaptchaError }}</div
              >
          </div>

          <md-card-actions class="md-layout btn-container">
            <md-button id="goBack" class="cancel-btn" :disabled="isSubmitting" @click="closeWindow"
              >Cancel</md-button
            >
            <md-button
              v-if="!verified"
              @click.prevent="sendEmailVerificationLink"
              id="sendVerifytLink"
              type="submit"
              class="verify-button"
              :disabled="isSubmitting"
              >Send Verify Link</md-button
            >
            <md-button
              v-else
              id="sendResetLink"
              type="submit"
              class="submit-button"
              :disabled="isSubmitting"
              >Send Reset Link</md-button
            >
          </md-card-actions>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import validationMixin from "../../validation/validation_mixin";
import { required, email } from "vuelidate/lib/validators";
import notification from "../../notification";
import restAdapter from "../../restAdapter";
import * as conf from "../../conf.yml";
import VueRecaptcha from "vue-recaptcha";
import auth from "../../auth";

export default {
  name: "userForgotPassword",
  mixins: [validationMixin],
  components: { VueRecaptcha },

  data: () => ({
    model: {
      email: null,
      password: null,
    },
    recaptcha : false,
    recaptchaError : "",
    addRecaptcha : false,
    isSubmitting: false,
    verified : true,
  }),
  validations: {
    model: {
      email: {
        required,
        email,
      },
    },
  },

  computed: {
    emailValidationError() {
      if (!this.$v.model.email.required) {
        return "The email is required";
      }

      if (!this.$v.model.email.email) {
        return "Invalid email";
      }

      return null;
    },
  },

  mounted(){
    this.addRecaptcha = conf.addCaptcha;
  },

  methods: {
    clearForm() {
      this.$v.$reset();
      this.model.email = null;
      this.model.password = null; 
      if(this.addRecaptcha){
        this.recaptcha = false;
        this.$refs.recaptcha.reset();
      }
    },
    sendResetPasswordLink() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      if(!this.recaptcha && this.addRecaptcha){
        this.recaptchaError = "Please select the captcha"
        return;
      }

      this.isSubmitting = true;

      const credentials = {
        email: this.model.email,
      };

      restAdapter
        .post("/api/forgot_password/", credentials)
        .then((response) => {
          if(response.data.notVerified){
            this.verified = false;
            this.isSubmitting = false;
          }
          else {
            this.isSubmitting = false;
            notification.success(
              "A password reset link has been sent to " + this.model.email
            );
            this.clearForm();
            this.closeWindow();
          }
        })
        .catch((error) => {
          this.isSubmitting = false;
          if (
            error.response &&
            (error.response.status == 400 || error.response.status == 401)
          ) {
            notification.errors({ emailPassword: ["Invalid email."] });
          } else {
            notification.error("Something went wrong, please try again later.");
          }
        });
    },
    sendEmailVerificationLink() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.isSubmitting = true;
      restAdapter
        .post('/api/email/resend',{email: this.model.email}).then(response => {
          this.isSubmitting = false;
          notification.success(
            "Verification email has been sent to " + this.model.email + ". " + "Please check your inbox"
          );
          auth.user.isRememberToken = false;
          auth.user.rememberToken = null;
          auth.user.token = null;
          auth.setAuth(auth.user);
          this.closeWindow();
        })
        .catch(error => {
          this.isSubmitting = false;
          if (
            error.response &&
            (error.response.status == 400 || error.response.status == 401)
          ) {
            notification.errors({emailPassword: ["Invalid email."]});
          } else {
            notification.error("Something went wrong, please try again later.");
          }
        });
    },
    onCaptchaVerified(){
      this.recaptcha = true;
      this.recaptchaError = "";
    },
    onExpired() {
      this.model.recaptcha = false;
      this.recaptchaError = "";
    },

    closeWindow(){
      this.$emit('close');
    },

    inputEmail(){
      this.verified = true;
    },
  },
};
</script>

<style lang="scss" scoped>

.forgot-password-form {
  justify-content: center;
}

.external-container{
  width: 470px;
}

.container{
  width: 340px;
  margin-bottom: 7px;
}

.title-container{
  padding: 15px 0px 5px 8px;
}

.email-container{
  padding: 10px 11px 10px 8px;
}

.md-card {
  box-shadow: none;
}

.title-color{
  width: fit-content;
  color: #442D65 !important;
  font-weight: 500;
  font-size: 18px;
  line-height: 116.1%;
}

.submit-button {
  width: 140px;
  height: 35px;
  border: 1px solid #473068;
  background: #473068 !important;
  color: $paginateText !important;
  border-radius: 3px !important;
}

.verify-button{
  width: 140px;
  height: 35px;
  border: 1px solid #473068;
  background: #473068 !important;
  color: $paginateText !important;
  border-radius: 3px !important;
}

.cancel-btn{
  width: 140px;
  height: 35px;
  border: 1px solid #473068;
  color: #473068;
  border-radius: 3px !important;
}

.btn-container{
  justify-content: space-between;
}

.verify_email_message{
  text-align: left;
  margin-left: 8px;
  margin-top: -30px;
  margin-bottom: 20px;
  color: red;
}
.verify_email_msg{
  text-align: left;
  margin-left: 8px;
  margin-top: -10px;
  margin-bottom: 20px;
  color: red;
}

.md-field.md-theme-default.md-focused label{
  color: #473068 !important;
}

.md-field.md-theme-default:before{
    background-color: #473068 !important;
}

</style>
